import './bootstrap';

document.getElementById("year_footer").innerHTML = new Date().getFullYear();

const styles = [
    'color: #F89C56',
    'font-size: 25px',
    'border: 1px solid black',
    'border-radius: 20px',
    'padding: 10px',
].join(';');
console.log('%cHello There. How can I help you? If you have any questions, please contact us by email: support@crmango.com', styles);
